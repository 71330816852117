import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import { isUserLoggedIn } from "@/auth/utils";
import { canNavigate } from "@/core/utils/acl/routeProtection";
import VueCompositionAPI from "@vue/composition-api";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import VuePusher from "vue-pusher";
import "@/core/utils/acl";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/treeselect";
import "@/core/plugins/toastification";
import "@/core/plugins/apexcharts";
import "@/core/plugins/flat-pickr";
import "@/core/plugins/signature";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

// Composition API
Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

Vue.prototype.$variableGlobal = Vue.observable({
  facility: null,
});

// API service init
/* ApiService.init(); */

Vue.use(VuePusher, {
  api_key: process.env.VUE_APP_PUSHER_KEY,
  options: {
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    authEndpoint: `${process.env.VUE_APP_RUTA_API}/api/broadcasting/auth`,
    encrypted: true,
    auth: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    },
  },
});
const routesWithoutToken = [
  "get-cupon"
]
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  if (!routesWithoutToken.includes(to.name)) {
    if (!canNavigate(to)) {
      // Redirect to login if not logged in
      if (!isLoggedIn) return next({ name: "login" });
  
      // this.$pusher.config.auth.headers.Authorization = `Bearer ${localStorage.getItem(
      //   "accessToken"
      // )}`;
  
      // If logged in => not authorized
      return next({ name: "404" });
    }
    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      //cambiar cuando tenga roles
      next({ name: "dashboard" });
    }
  }

  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  return next();
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
