<template>
  <component :is="layout">
    <router-view></router-view>
  </component>
</template>

<style lang="scss">
.form-control {
  height: calc(1.5em + 1.1rem + 2px) !important;
}
.input-group-text {
  padding: 0.5rem 1rem !important;
}
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { provideToast } from "vue-toastification/composition";

const Layout = () => import("@/view/layout/Layout.vue");
const LayoutFull = () => import("@/view/layout/LayoutFull.vue");

export default {
  name: "MetronicVue",
  components: {
    Layout,
    LayoutFull,
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout`;
    },
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
  setup() {
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });
  },
};
</script>