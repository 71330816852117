const state = {
  closingCash: {},
};

const getters = {
  getClosing(state) {
    return state.closingCash;
  },
};

const actions = {
  setClosingCash(context) {
    context.commit("defineClosing");
  },
};

const mutations = {
  defineClosing(state) {
    state.closingCash = state.closingCash;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
