import ability from './ability'

export const canNavigate = to => to.matched.some(route => {
    if (route.name === 'login') return true
    if (route.meta.resource === 'freeRoute' && localStorage.getItem('userData')) return true
    return ability.can(route.meta.action || 'read', route.meta.resource)
})


export const _ = undefined
